import React, { useState } from "react";
import { createTrackingEvent } from "lib/tracking";
import * as widont from "widont";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import FAQDropdown from "./FAQDropdown";
import PropTypes from "prop-types";
import * as FAQStyling from "./FAQ.module.scss";
import { graphql } from "gatsby";

const FAQCategory = ({ active, title, onClick }) => {
  return (
    <li
      className={
        active
          ? `${FAQStyling.category} ${FAQStyling.active} mb-5`
          : `${FAQStyling.category} mb-5`
      }
      onClick={() => {
        onClick();
        createTrackingEvent(
          "Storefront - FAQ Category Selection",
          {
            "FAQ Click Category": title,
          },
          "Click",
          {
            "FAQ Click Category": title,
          },
          title
        );
      }}
      aria-hidden="true"
    >
      <span className={FAQStyling.dot}></span>
      <span className={`text5 mb-5 ${FAQStyling.categoryHeadline}`}>
        {title}
      </span>
    </li>
  );
};

const FAQ = ({ block }) => {
  const title = block.title;
  const subtitle = block.subtitle;
  const faqSections = block.sections;

  const [selectedSection, setSelectedSection] = useState(faqSections[0]);

  const handleSectionClick = (value) => () => {
    setSelectedSection(value);
  };

  return (
    <Section className={FAQStyling.sectionWrapper}>
      <div className="row mb-7">
        <div className="col sm-12 m-7">
          <h2 className="text7">{title}</h2>
        </div>
        {subtitle && (
          <div className="col sm-12 m-5">
            <p>{widont(subtitle)}</p>
          </div>
        )}
      </div>
      <div className="row">
        <ul className={`col c-6 ${FAQStyling.categoriesContainer}`}>
          {faqSections.map((section) => (
            <FAQCategory
              key={section.id}
              title={section.title}
              active={section === selectedSection}
              onClick={handleSectionClick(section)}
            />
          ))}
        </ul>
        <div
          className={`col c-6 inline-elements ${FAQStyling.dropDownSection}`}
        >
          {selectedSection.items.map((item) => {
            return <FAQDropdown key={item.id} item={item} />;
          })}
        </div>
      </div>
    </Section>
  );
};

export default FAQ;

FAQ.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            answertext: PropTypes.shape({}).isRequired
          })
        ).isRequired,
      })
    ).isRequired,
  }),
};

FAQCategory.propTypes = {
  active: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const query = graphql`
  fragment FAQ on DatoCmsFaq {
    model {
      apiKey
    }
    id: originalId
    title
    subtitle
    sections {
      id: originalId
      ... on DatoCmsFaqsection {
        id: originalId
        title
        items {
          ... on DatoCmsFaqitem {
            id: originalId
            title
            answertext {
              value
            }
          }
        }
      }
    }
  }
`;
