import React, { useState } from "react";
import * as widont from 'widont'
import { createTrackingEvent } from "lib/tracking";
import { Icon } from "@soundtrackyourbrand/ui/src/Icon";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import * as FAQDropDownStyling from "./FAQDropdown.module.scss";

const FAQDropdown = ({ item }) => {
  const question = item.title;
  const answer = item.answertext;
  const [showDropdown, setDropdown] = useState(false);

  return (
    <div className={FAQDropDownStyling.dropdownWrapper}>
      <div
        aria-hidden="true"
        role="button"
        tabIndex={0}
        onClick={() => {
          setDropdown(!showDropdown);
          if (!showDropdown) {
            createTrackingEvent(
              "Storefront - FAQ Content Accordion Click",
              {
                "Accordion Click Name": question,
              },
              "Click",
              {
                "Accordion Click Name": question,
              },
              question
            );
          }
        }}
        className={`${FAQDropDownStyling.question}`}
      >
        <p className="c-grow mb-0">{widont(question)}</p>
        <span>
          <Icon
            name="chevron-down"
            className={
              showDropdown
                ? `arrowDropdown arrowDropdownActive`
                : `arrowDropdown`
            }
          />
        </span>
      </div>
      <div
        className={
          showDropdown
            ? `${FAQDropDownStyling.answer}`
            : `${FAQDropDownStyling.answer} ${FAQDropDownStyling.active}`
        }
      >
        <StructuredTextRenderer data={answer} />
      </div>
    </div>
  );
};

export default FAQDropdown;
